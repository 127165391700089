.pearls-page {
  background-color: #0e111a;
}
.pearls-list{
  margin-top: 30px;
}
.pearls-list .boosters-list-item-btn {
  margin-top: 4px;
}
.pearls-page .boosters-list-wrapper {
  margin: 15px 0 -15px;
}
.modal-bg.pearl-images {
  flex-direction: column;
  gap: 20px;
}
.pearl-images-container {
  flex: 1;
  overflow: auto;
}
.pearl-images-buttons {
  display: flex;
  width: 100%;
  gap: 6px;
}
.pearl-images-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 7px;
}
.pearl-image img{
  border-radius: 6px;
  max-width: 100%;
}
.pearl-images-buttons button {
  border-radius: 6px;
  height: 46px;
}
.pearl-images-buttons .back {
  background: #32384d;
  width: 46px;
}
.pearl-images-buttons .more {
  flex: 1;
}
