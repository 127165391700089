.entry-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px 20px;
}
.entry-socials{
    display: flex;
    gap: 8px;
    margin: 20px 0 30px;
}
.entry-page > p{
    font-size: 14px;
    font-weight: 300;
}

.play-btn{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16.5px 0;
    text-shadow: 0px 0px 25px #00A3FF;
    background: rgb(27,34,57);
    background: -moz-radial-gradient(circle, rgba(27,34,57,1) 0%, rgba(14,17,26,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(27,34,57,1) 0%, rgba(14,17,26,1) 100%);
    background: radial-gradient(circle, rgba(27,34,57,1) 0%, rgba(14,17,26,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b2239",endColorstr="#0e111a",GradientType=1);
    border-radius: 16px;
    margin: 14px 0 30px;
}
.entry-desc-container{
    position: relative;
    padding-top: 308px;
  }
.entry-img{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.entry-description-block{
    box-shadow: 0px 4px 150px black;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 270px;
    background-image: url(./entry-desc-img.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
}
.entry-description-content{
    padding: 20px;
}
.entry-description-content h2{
    color: black;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 6px;
}
.entry-description-content p{
    font-size: 14px;
    color: #8A8A8A;
}
.entry-description-content hr{
    height: 2px;
    width: 100%;
    background-color: #E6E6E6;
    margin: 15px 0 20px;
}
/* .entry-description-block img{
    width: 100%;
    max-height: 270px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
} */