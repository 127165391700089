@import url('./assets/fonts/Stolzl/stylesheet.css');
@import url('./assets/fonts/SFProDisplay/stylesheet.css');
*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
input
hr a {
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: white;
  font-family: 'Stolzl';
  -webkit-tap-highlight-color: transparent;
}
#root{
  height: inherit;
  width: 100%;
  overflow-x: hidden;
}

html,
body {
    overflow: hidden;
    background: url(./assets/images/bg.jpg) no-repeat center/cover fixed;
    color: #fff;
  height: 100vh;
}
h1{
  font-size: 27px;
}

button{
  cursor: pointer;
  position: relative;
}
.btn-loading-icon{
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
.page-wrapper{
  width: 100%;
  overflow-x: hidden;
  height: inherit;
    padding: 40px 20px 110px;
}
.page-shadow{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 160px;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
  background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
.sand-button{
  color: white;
  background: rgb(135, 107, 82);
  background: -moz-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#876b52",endColorstr="#be9575",GradientType=1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16.5px 10px;
}

.sand-text{
  background: rgb(135, 107, 82);
  background: -moz-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sand-text2{
  background: #B59F8C;
  background: radial-gradient(circle, #B59F8C 0%, #EFE3C2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* NAVIGATION */

nav{
  z-index: 5;
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  border-radius: 16px;
  padding: 6px;
  display: flex;
  backdrop-filter: blur(5px);
  align-items: stretch;
  background: rgb(0,0,0);
background: -moz-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}
.nav-button{
  transition: .2s;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border-radius: 12px;
  padding: 9px 0;
}
.nav-button.current{
  background-color: rgba(0, 0, 0, 0.5);
}
.nav-button.current svg path:not(.non-fill){
  fill: #B59F8C;
  fill-opacity: 1;
}
.nav-button.current svg path.stroke-fill{
  stroke:#B59F8C;
  stroke-opacity: 1;
  opacity: 1;

}
/* USER BALANCE */
.user-balance-container {
  background-color: #0a0c13;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  border-radius: 100px;
}
.user-balance-container p {
  font-size: 14px;
}
.user-profile,
.user-balance {
  display: flex;
  gap: 10px;
  align-items: center;
}
.user-profile img ,.energy-circle{
  width: 34px;
  border-radius: 50%;
  height: 34px;
}
.energy-circle img{
  height: 23px;
}
.user-balance span {
  color: rgba(255, 255, 255, 0.5);
}


/* MODAL */
.modal-bg{
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  padding: 20px;
}
.modal-body{
  background-color: #181B26;
  border-radius: 12px;
  padding: 26px;
  position: relative;
  width: 100%;
}
.modal-close{
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}