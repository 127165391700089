.tap-page{
    display: flex;
    flex-direction: column;
}
.income{
    padding: 19px 0;
}
.income,.income div{
    display: flex;
    gap: 18px;
    justify-content: center;
    align-items: center;
}
.income div{
    gap: 6px;
}
.income div p{
    font-size: 12px;
}
.income div span{
    color: rgba(255, 255, 255, 0.5);
}
.about-token-container{
    display: flex;
    justify-content: center;
}
.about-token{
    border-radius: 54px;
    padding: 9px 10px;
    display: flex;
    gap: 6px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-style: inset;
}
.oyster-container{
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.oyster{
    flex: 1;
    cursor: pointer;
    max-width: 100%;
    max-height: 90%; /* Ограничивает изображение в пределах контейнера */
    object-fit: contain; /* Масштабирует изображение с сохранением пропорций */
}
.energy-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.energy-value{
    border-radius: 53px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.energy-value img{
    height: 19px;
}
.energy-value p{
    color: rgba(255, 255, 255, 0.5);
}
.energy-value span{
    color: white;
}
.energy-progress-container{
    height: 26px;
    border-radius: 50px;
    width: 100%;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    padding: 3px;
}
.energy-progress-wrapper{
    width: inherit;
    background: inherit;
    height: 100%;
    padding: inherit;
    border-radius: inherit;
}
.energy-progress-line-container{
    width: inherit;
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
}
.energy-progress-line{
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: rgb(135,107,82);
background: -moz-radial-gradient(circle, rgba(135,107,82,1) 0%, rgba(190,149,117,1) 100%);
background: -webkit-radial-gradient(circle, rgba(135,107,82,1) 0%, rgba(190,149,117,1) 100%);
background: radial-gradient(circle, rgba(135,107,82,1) 0%, rgba(190,149,117,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#876b52",endColorstr="#be9575",GradientType=1);
}