.earn-page {
  background: #0e111a;
  overflow: hidden;
}
.earn-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: inherit;
}
.earn-page-section {
  height: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.earn-page-section>*{
  width: 100%;
}
.earn-nav {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #1e2330;
  border-radius: 16px;
  padding: 6px;
  display: flex;
  align-items: stretch;
  margin-top: 10px;
  z-index: 2;
}
.earn-nav li {
  flex: 1;
}
.earn-nav li.active button {
  background-color: #32384d;
}
.earn-nav li button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 11.5px 0;
  font-family: "SF Pro Display";
}

.earn-page h1 {
  margin: 30px 0 20px;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}
/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
.daily-reward-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  gap: 8px;
  flex: 1;
  padding-bottom: 210px;
}
.daily-reward-item {
  flex: 1 1 calc(25% - 8px);
  background-color: #1e2330;
  border-radius: 12px;
  padding: 1px;
  background: rgb(135, 107, 82);
  background: -moz-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#876b52",endColorstr="#be9575",GradientType=1);
}
.daily-reward-item-wrapper {
  padding: 12px;
  border-radius: inherit;
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: center;
}
.daily-reward-item span {
  font-size: 14px;
  font-family: "SF Pro Display";
}
.reward-value {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 33px;
  color: rgb(135, 107, 82);
}
.daily-reward-item.current .daily-reward-item-wrapper {
  background-color: #1e2330;
}
.daily-reward-item.current .reward-value {
  color: white;
  background: rgb(135, 107, 82);
  background: -moz-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#876b52",endColorstr="#be9575",GradientType=1);
}
.daily-reward-item.current ~ .daily-reward-item {
  background: #1e2330;
}
.daily-reward-item.current ~ .daily-reward-item .daily-reward-item-wrapper {
  background: #1e2330;
}
.daily-reward-item.current ~ .daily-reward-item .reward-value {
  background-color: #474f66;
  color: white;
}
.daily-reward-gain{
  position: sticky;
  bottom: 205px;
  left: 0;
  width: 100%;
}
/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
.earn-boosters {
  overflow: auto;
  height: inherit;
  padding-bottom: 210px;
}
.earn-boosters-stats{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 15px; */
}
.earn-boosters-stats li{
  display: flex;
  align-items: center;
  gap: 6px;
}
.earn-boosters-stats li img,.earn-boosters-stats li svg{
  height: 26px;
  width: 26px;
}
.earn-boosters-stats div{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.earn-boosters-stats div p{
  font-size: 14px;
}
.earn-boosters-stats div span{
  font-size: 10px;
  color: rgba(255, 255, 255, 0.507);
}
.boosters-list-wrapper{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin: 30px 0 0;
}
.boosters-list-wrapper .divider{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.boosters-list-wrapper .divider hr{
  display: block;
  height: 1px;
  flex: 1;
  background-color: #474F66;
}
.boosters-list-wrapper .divider h3{
font-size: 12px;
font-weight: 400;
}
.boosters-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
}
.boosters-list-item{
  background-color: #181B26;
  padding: 14px;
  display: flex;
  align-items: stretch;
  gap: 12px;
  border-radius: 12px;
}
.boosters-list-item img,.energy-booster-img{
  background-color: #32384D;
  height: 80px;
  width: 80px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.energy-booster-img svg{
  width: 58px;
  height: 54px;
}
.energy-booster-img::before {
  content: '';
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #B8A38F;
  filter: blur(20px);
  z-index: 1;
}
.boosters-list-item-desc{
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  justify-content: space-between;
  flex: 1;
  height: 80px;
}
.boosters-list-item-desc h4{
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
}
.boosters-list-item-btn{
  padding: 10px;
  display: flex;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  background-color: #32384D;
}
.boosters-list-item-btn.active{
  background: rgb(135, 107, 82);
  background: -moz-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(135, 107, 82, 1) 0%,
    rgba(190, 149, 117, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#876b52",endColorstr="#be9575",GradientType=1);
}
.boosters-list-item-btn *{
  font-size: 14px;
}
.boosters-list-item-btn-container{
  display: flex;
  width: 100%;
  gap: 4px;
}
.boosters-list-item-btn-container button{
  flex: 1;
}
.boosters-list-item-cost{
  padding: 10px;
  background-color: #32384D;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.boosters-list-item-cost svg{
  width: 15px;
  height: 15px;
}
/* |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
.tapping-goods-list{
  /* margin-top: 30px; */
}

.tapping-goods-list img{
  width: 120px;
  object-fit: cover;
  object-position: top;
}
.tapping-good-desc{
  gap: 2px;
}
.tapping-goods-list-item{
  cursor: pointer;
}
.tapping-good-cost-text{
  font-size: 10px;
  margin-top: 6px;
}
.per-tap{
  display: flex;
  align-items: center;
  gap: 3px;
}
.per-tap p{
  font-size: 14px;
}
.per-tap span{
  color: rgba(255, 255, 255, 0.5);
}
.per-tap svg,.cost-variants svg{
  width: 20px;
  height: 20px;
}
.cost-variants{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cost-variants li{
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 3px;
}

.buy-boost-buttons{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: stretch;
}
.buy-boost-select-currency{
  width: 100%;
  padding: 16.5px 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #292F40;
  font-size: 16px;
  color: #7A8299;
  transition: .2s;
}
.buy-boost-buttons svg{
  width: 16px;
  height: 16px;
  filter: grayscale(1);
}
.buy-boost-select-currency.active{
color: white;
}
.buy-boost-select-currency.active svg{
  filter: grayscale(0);
  }
.buy-boost-btn{
  width: 100%;
  margin-top: 20px;
}
.tg-currency.active{
  background: rgb(105,150,255);
  background: linear-gradient(10deg, rgba(105,150,255,1) 0%, rgba(192,98,208,1) 100%);
}
.fr-currency.active{
  background-color: #165EF0;
}
.cn-currency.active{
  background-color: #B54708;
}
.fr-currency.active svg{
  filter: brightness(2);
}
.not-available-info{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 16px;
  background-color: #181B26;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  transform: translateY(calc(100% + 6px));
}
.not-available-info:not(:has(p)){
  display: none;
}

.not-available-info p{
color: #7A8299;
font-size: 16px;
}
.ton-connect-button{
  border-radius: 16px;
  padding: 16.5px;
  display: flex;
  align-items: center;
  background-color: #181B26;
  margin-bottom: -10px;
  margin-top: 15px;
  width: 100%;
  gap: 7px;
}
.ton-connect-button svg,.ton-connect-button img{
  width: 38px;
  height: 38px;
}
.ton-connect-button img{
  border-radius: 6px;
}
.ton-connect-button p{
  font-size: 16px;
  font-weight: 500;
}
.ton-connect-button span{
  margin-left: auto;
  color: #ffffff7c;
  letter-spacing: 1px;
}
.disconnect-btn{
  border-radius: 12px;
  background-color: #32384D;
}