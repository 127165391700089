.friends-page-info {
  gap: 26px;
  margin-top: 30px;
}
.friends-page-info h1 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  background: rgb(181, 159, 140);
  background: radial-gradient(
    circle,
    rgba(181, 159, 140, 1) 0%,
    rgba(239, 227, 194, 1) 100%
  );
  -webkit-background-clip: text;
}
.friends-page-info,
.friends-page-info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.has-friends {
  flex-direction: row !important;
  gap: 10px;
  justify-content: center;
}
.has-friends .friends-page-gift {
  width: 130px;
}
.friends-page-gift {
  width: 170px;
  -webkit-filter: drop-shadow(0px 0px 50px #ff4800e5);
  filter: drop-shadow(0px 0px 50px #ff4800e5);
  margin-bottom: 16px;
}
.invite-friend-desc {
  width: 100%;
  border-radius: 16px;
  background-color: #181b26;
  padding: 16px;
  align-items: flex-start !important;
  gap: 8px;
  margin-bottom: 5px;
}
.invite-friend-desc h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}
.invite-friend-desc span {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.invite-friend-desc span svg {
  width: 13px;
  height: 13px;
}
.invite-friend-buttons-wrapper {
  width: 100%;
  display: flex;
  gap: 5px;
}
.invite-friend-buttons-wrapper button {
  background-color: #32384d;
  border-radius: 16px;
  padding: 16.5px 10px;
  flex: 1;
}
.invite-friend-buttons-wrapper .sand-button {
  background: rgb(181, 159, 140);
  background: radial-gradient(
    circle,
    rgba(181, 159, 140, 1) 0%,
    rgb(211 191 165) 100%
  );
}
.invite-friend-counter {
  position: fixed;
  left: 20px;
  backdrop-filter: blur(5px);
  bottom: 110px;
  margin-top: 10px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 15px;
  width: calc(100% - 40px);
}
.invite-friend-counter svg {
  width: 14px;
  height: 14px;
}
.invite-friend-counter svg path {
  fill: #efe3c2;
  fill-opacity: 1 !important;
}
.friends-list-container {
  border-radius: 16px;
  margin: 15px 0 60px;
  padding: 16px;
  background-color: #0e111a;
}
.friends-list-container .boosters-list-wrapper {
  margin-top: 10px;
}
.friends-list {
}
.friends-list-item {
}
.friends-list-item img {
  width: 45px;
  height: 45px;
}
.friends-list-item .boosters-list-item-desc {
  height: auto;
  gap: 5px;
}
